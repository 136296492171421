<template>
    <div class="about">
        <section class="section hero vh">
            <div class="container">
                <div class="hero-body columns">
                    <div class="column is-7">
                        <div class="title l pb-3">About Us</div>
                        <vue-markdown-plus v-if="about"
                                           :scr="strapiApiUrl"
                                           class="content">{{ about.body }}
                        </vue-markdown-plus>

                        <div class="mt-4">
                            <SocialMediaIcons />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-1">
                        <a href="#section2">
                            <i class="
                                    fas
                                    fa-chevron-down fa-3x
                                    has-text-primary
                                    scroll-down-arrow
                                    hide-mobile
                                "></i>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <!-- <section v-if="about && about.rnd"
                 id="section2"
                 class="section is-medium container">
            <div class="container">
                <h3 class="title is-3 has-text-centered pb-5">
                    Our R&amp;D Program
                </h3>
                <div class="columns is-centered">
                    <div class="column is-7">
                        <div class="content subtitle">
                            <vue-markdown-plus v-if="about"
                                               :scr="strapiApiUrl"
                                               class="content">{{ about.rnd }}
                            </vue-markdown-plus>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

        <!-- <section class="section is-medium container" id="section2">
            <h3 class="title is-3 has-text-centered pb-5">Solutions</h3>
            <div class="columns is-multiline m-0">
                <div
                    v-for="(card, index) in cards"
                    :key="index"
                    class="column is-half-tablet is-one-quarter-widescreen"
                >
                    <div class="has-text-centered p-6">
                        <i v-bind:class="card.icon" class="fa-4x has-text-primary specialty-icon"></i>
                        <h5 class="title is-5 specialty-mobile-title mb-2 mt-4">{{ card.title }}</h5>
                        <p>{{card.text}}</p>
                    </div>
                </div>
            </div>
        </section> -->

        <section class="section is-small container pt-3"
                 v-scrollanimation>
            <h3 class="title is-3 has-text-centered pb-5">Partners</h3>
            <div class="is-flex is-flex-wrap-wrap is-justify-content-center px-6">
                <img class="client-logo"
                     src="../assets/About/logos/adl.png" />
                <img class="client-logo"
                     src="../assets/About/logos/air-force.png" />
                <!-- <img class="client-logo" src="../assets/About/logos/arl.jpg" /> -->
                <img class="client-logo"
                     src="../assets/About/logos/c2_competency_catalyst.png" />
                <img class="client-logo"
                     src="../assets/About/logos/Credential_Engine.png" />
                <img class="client-logo"
                     src="../assets/About/logos/darpa.png" />
                <img class="client-logo"
                     src="../assets/About/logos/devcom.png" />
                <img class="client-logo"
                     src="../assets/About/logos/gift.png" />
                <img class="client-logo"
                     src="../assets/About/logos/deloitte.png" />
                <img class="client-logo"
                     src="../assets/About/logos/eXtension.png" />
                <img class="client-logo"
                     src="../assets/About/logos/georgia-tech.png" />
                <img class="client-logo"
                     src="../assets/About/logos/georgia-virtual.png" />
                <img class="client-logo"
                     src="../assets/About/logos/hrblock.png" />
                <img class="client-logo"
                     src="../assets/About/logos/impact_collaborative.png" />
                <img class="client-logo"
                     src="../assets/About/logos/marines.png" />
                <img class="client-logo"
                     src="../assets/About/logos/mcam.jpg" />
                <img class="client-logo"
                     src="../assets/About/logos/navy.png" />
                <img class="client-logo"
                     src="../assets/About/logos/navsea.jpg" />
                <img class="client-logo"
                     src="../assets/About/logos/nawcad.png" />
                <img class="client-logo"
                     src="../assets/About/logos/nps.svg" />
                <img class="client-logo"
                     src="../assets/About/logos/nsf.png" />
                <img class="client-logo"
                     src="../assets/About/logos/osu.png" />
                <img class="client-logo"
                     src="../assets/About/logos/solar-turbines.png" />
                <img class="client-logo"
                     src="../assets/About/logos/uo.png" />
                <img class="client-logo"
                     src="../assets/About/logos/usda.png" />
                <img class="client-logo"
                     src="../assets/About/logos/walgreen.png" />
            </div>
            <div class="mt-6 has-text-centered">
                <router-link :to="{ path: '/government-solutions', hash: '#naics-codes' }">
                    <span class="transparent-button">NAICS Codes for our government partners<i
                           class="fas fa-arrow-right"></i></span>
                </router-link>
            </div>
        </section>

        <section class="section is-small container pb-6">
            <div class="title l has-underline mb-6">Meet the Team</div>
            <div class="columns is-mobile is-multiline is-centered">
                <div v-for="leader in leaders"
                     :key="leader.title"
                     class="column is-one-third-desktop is-half-tablet">
                    <div class="leader has-text-centered"
                         :class="{ 'is-draft': leader.published_at === null }">
                        <div class="leader-img"
                             :style="{
                                 backgroundImage: `url(${`https://cms.eduworks.com` + getSmallImage(leader)
                                     })`,
                             }"></div>
                        <h5 class="title is-6 mt-4 mb-0 has-text-weight-bold">
                            {{ leader.fullName }}
                        </h5>
                        <div>{{ leader.title }}</div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="columns is-mobile is-multiline is-centered">
                <div v-for="(person, index) in generalTeam"
                     :key="index + person.title"
                     class="column is-one-fifth-desktop is-one-quarter-tablet is-half-mobile">
                    <div class="team-member has-text-centered"
                         :class="{ 'is-draft': person.published_at === null }">
                        <img v-if="person.image"
                             class="team-img"
                             :style="{
                                 backgroundImage: `url(${`https://cms.eduworks.com` + getSmallImage(person)
                                     })`,
                             }"
                             style="backgroundposition: top center" />
                        <h5 class="title is-6 mt-4 mb-0 has-text-weight-bold">
                            {{ person.fullName }}
                        </h5>
                        <div>{{ person.title }}</div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section is-small has-text-centered mt-0">
            <div class="title is-4">Want to know more?</div>
            <router-link :to="{ name: 'Contact' }"
                         class="button is-primary is-radiusless has-text-weight-bold primary-button">
                Contact Us
                <i class="fas fa-arrow-right ml-2 primary-button-icon"></i>
            </router-link>
        </section>
    </div>
</template>

<script>
import VueMarkdownPlus from "vue-markdown-plus";
import SocialMediaIcons from "../components/socialMediaIcons.vue";

export default {
    name: "About",
    components: {
        SocialMediaIcons,
        "vue-markdown-plus": VueMarkdownPlus,
    },
    data: function () {
        return {
            cards: [
                {
                    icon: "fas fa-microscope mb-4",
                    title: "Competency Based Training",
                    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
                },
                {
                    icon: "fas fa-code mb-4",
                    title: "Artificial Intelligence Machine Learning",
                    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
                },
                {
                    icon: "fas fa-chart-bar mb-4",
                    title: "People Analytics",
                    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
                },
                {
                    icon: "fas fa-spell-check mb-4",
                    title: "Educational Technology",
                    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
                },
            ],
        };
    },
    metaInfo: {
        // Default subtitle if no subcomponent (%s) is defined by other page views
        title: "About Us",
        // all titles will be injected into this template unless template is set to null
        titleTemplate: "Eduworks | %s",
    },
    mounted() {
        if (!this.$store.state.about) this.$store.dispatch("getAbout");
        if (this.$store.state.teamMembers.length < 1)
            this.$store.dispatch("getTeamMembers");
    },
    computed: {
        strapiApiUrl() {
            return this.$store.state.strapiApiUrl;
        },
        about() {
            return this.$store.state.about;
        },
        teamMembers() {
            return this.$store.state.teamMembers;
        },
        leaders() {
            let leaders = [];
            this.teamMembers.forEach((teamMember) => {
                if (teamMember.leaderSpotlight === true) {
                    leaders.push(teamMember);
                }
            });
            leaders.sort(function (a, b) {
                return a.order.localeCompare(b.order);
            });
            return leaders;
        },

        generalTeam() {
            let generalTeam = [];
            this.teamMembers.forEach((teamMember) => {
                if (teamMember.leaderSpotlight !== true) {
                    generalTeam.push(teamMember);
                }
            });
            generalTeam.sort(function (a, b) {
                if (a.leaderSpotlight != true && b.leaderSpotlight != true) {
                    return a.fullName.localeCompare(b.fullName);
                }
            });
            return generalTeam;
        },
    },
    methods: {
        getSmallImage(member) {
            if (member.image) {
                if (member.image.formats) {
                    if (member.image.formats.small)
                        return member.image.formats.small.url
                    if (member.image.formats.medium)
                        return member.image.formats.medium.url
                    if (member.image.formats.large)
                        return member.image.formats.large.url
                    else
                        return member.image.url
                } else {
                    return member.image.url
                }
            } else return null
        }
        // showBio: function (leader) {
        //     this.activeLeader = leader;
        // },
        // highlightLeader: function (leader) {
        //     if (this.activeLeader.name == leader.name) {
        //         return "active-leader";
        //     }
        // },
        // getImg(pic) {
        //     if (!pic) return;
        //     return require(pic);
        // },
        // sortTeam(teamMembers) {
        //     teamMembers.sort(function (a, b) {
        //         if (a.leaderSpotlight != true && b.leaderSpotlight != true) {
        //             return a.fullName.localeCompare(b.fullName);
        //         } else if (
        //             a.leaderSpotlight === true &&
        //             b.leaderSpotlight === true
        //         ) {
        //             return a.order.localeCompare(b.order);
        //         }
        //     });
        //     console.log(teamMembers);
        //     return teamMembers;
        // },
    },
};
</script>